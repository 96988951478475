import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const FeaturedMiniWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const FeaturedContent = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-row-start: 1;
  align-content: center;
  justify-content: center;
  z-index: 997;
  background: rgba(0, 0, 0, 0.4);
`
const FeaturedImageMini = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-row-start: 1;
  height: 350px;
  overflow: hidden;
  width: 100%;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    height: 200px;
  }
`
const Title = styled.h2`
  color: white;
  font-family: 'Signature', cursive;
  font-size: 4em;
  font-weight: 300;
  text-align: center;

  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 3rem;
  }
`
const Category = styled.h4`
  padding: 5px 10px;
  margin: 0 auto;
  color: ${props => props.theme.colors.secondary};
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 300;
  background: ${props => props.theme.colors.base};
  width: fit-content;
  border-radius: 5px;
`

const FeaturedMini = ({ slug, featuredImage, title, category }) => {
  return (
    <FeaturedMiniWrapper>
      <FeaturedImageMini>
        <Link to={`/${slug}/`}>
          <Img fluid={featuredImage.fluid} alt={featuredImage.title} />
        </Link>
      </FeaturedImageMini>
      <FeaturedContent>
        <div className="post-content-container">
          <Link to={`/category/${category.slug}`}>
            <Category>{category.categoryName}</Category>
          </Link>
          <Link to={`/${slug}/`}>
            <Title>{title}</Title>
          </Link>
        </div>
      </FeaturedContent>
    </FeaturedMiniWrapper>
  )
}

export default FeaturedMini

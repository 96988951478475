import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const FeaturedWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const FeaturedImage = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-row-start: 1;
  height: 501px;
  overflow: hidden;
  width: 100%;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    height: 100%;
  }
`
const FeaturedContent = styled.div`
  display: grid;
  grid-column-start: 1;
  grid-row-start: 1;
  align-content: center;
  justify-content: center;
  z-index: 997;
  background: rgba(0, 0, 0, 0.4);
`

const Title = styled.h2`
  color: ${props => props.theme.colors.base};
  font-family: 'Signature', cursive;
  font-size: 5em;
  font-weight: 300;
  text-align: center;
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 3rem;
  }
`
const Category = styled.h4`
  padding: 5px 10px;
  margin: 0 auto;
  color: ${props => props.theme.colors.secondary};
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2rem;
  background: ${props => props.theme.colors.base};
  width: fit-content;
  border-radius: 5px;
`

const FeaturedMain = ({ slug, featuredImage, title, category }) => {
  return (
    <FeaturedWrapper>
      <FeaturedImage>
        <Link to={`/${slug}/`}>
          <Img fluid={featuredImage.fluid} alt={featuredImage.title} />
        </Link>
      </FeaturedImage>
      <FeaturedContent>
        <div className="post-content-container">
          <Link to={`/category/${category.slug}`}>
            <Category>{category.categoryName}</Category>
          </Link>
          <Link to={`/${slug}/`}>
            <Title>{title}</Title>
          </Link>
        </div>
      </FeaturedContent>
    </FeaturedWrapper>
  )
}

export default FeaturedMain

import React from 'react'
import styled from 'styled-components'
import FeaturedMain from '../components/FeaturedMain'
import FeaturedMini from '../components/FeaturedMini'

const FeaturedContainer = styled.div`
  border-radius: 5px;
  display: grid;
  margin-bottom: 4rem;
  overflow: hidden;
  width: 100%;
  a {
    color: ${props => props.theme.colors.secondary};
    text-decoration: none;
  }
`

const FeaturedMiniContainer = styled.div`
  background: ${props => props.theme.colors.base};
  display: grid;
  grid-template-columns: 50% 50%;
  overflow: hidden;
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    grid-template-columns: 1fr;
  }
`

const Featured = ({ featured1, featured2, featured3 }) => {
  return (
    <FeaturedContainer>
      <FeaturedMain {...featured1} />
      <FeaturedMiniContainer>
        <FeaturedMini {...featured2} />
        <FeaturedMini {...featured3} />
      </FeaturedMiniContainer>
    </FeaturedContainer>
  )
}

export default Featured

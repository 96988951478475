import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Featured from '../components/Featured'
import CardList from '../components/CardList'
import Card from '../components/Card'
import CardReverse from '../components/CardReverse'
import Logo from '../components/Logo'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import OutfitBanner from '../components/OutfitBanner'
import BeautyProductBanner from '../components/BeautyProductBanner'

const Index = ({ data, pageContext, location }) => {
  const posts = data.allContentfulBlogPost.edges
  const featuredPost0 = posts[0].node
  const featuredPost1 = posts[1].node
  const featuredPost2 = posts[2].node
  const { currentPage } = pageContext
  const isFirstPage = currentPage === 1

  return (
    <Layout pathname={location.pathname}>
      <SEO />
      <Helmet>
        {isFirstPage ? (
          <title>{`${
            config.siteTitle
          } | Beauty, Fashion & Lifestyle Blog`}</title>
        ) : (
          <title>{`${
            config.siteTitle
          } | Beauty, Fashion & Lifestyle Blog - Page ${currentPage}`}</title>
        )}
      </Helmet>
      <Container>
        <Logo />
        {isFirstPage ? (
          <CardList>
            <Featured
              featured1={featuredPost0}
              featured2={featuredPost1}
              featured3={featuredPost2}
            />
            {posts.slice(3).map(({ node: post }, index) =>
              index % 2 === 0 ? (
                <Card key={post.id} {...post} />
              ) : (
                <div key={index}>
                  <CardReverse key={post.id} {...post} />
                  {index === 1 && <OutfitBanner />}
                  {index === 3 && <BeautyProductBanner />}
                </div>
              )
            )}
          </CardList>
        ) : (
          <CardList>
            {posts
              .slice(3)
              .map(({ node: post }, index) =>
                index % 2 === 0 ? (
                  <Card key={post.id} {...post} />
                ) : (
                  <CardReverse key={post.id} {...post} />
                )
              )}
          </CardList>
        )}
      </Container>
      <Pagination context={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: [publishedDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug
          category {
            slug
            categoryName
          }
          tag
          publishedDate(formatString: "MMMM DD, YYYY")
          featuredImage {
            title
            fluid(maxWidth: 1050, cropFocus: FACES, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              url
            }
          }
          content {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 450)
            }
          }
        }
      }
    }
  }
`

export default Index
